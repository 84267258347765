
import { defineComponent, reactive, ref, PropType, watch, computed } from '@vue/composition-api'
import { cloneDeep } from 'lodash-es'

import CommonAddEditDialog from '@/components/common/CommonAddEditDialog.vue'

import { useNotify } from '@/store'

import { isRequired, isZipCode } from '@/utils/validation'
import { isOfType } from '@/utils/types/isOfType'
import { handleError } from '@/utils/handleError'
import { mapBasicEntityToIdWithForm } from '@/utils/mapBasicEntityToIdWithForm'

import { useCreateWorkPlace, useUpdateWorkPlace } from '@/api/workPlace'

import { WorkPlace, NewWorkPlace } from '@/api/types/workPlace'
import { DATA_TYPE, FormField, FORM_FIELDS_ENUM } from '@/utils/types/formField'

export default defineComponent({
  name: 'AddEditWorkPlaceDialog',
  components: {
    CommonAddEditDialog,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    workPlaceToEdit: {
      type: Object as PropType<WorkPlace>,
      default: null,
    },
  },
  setup: (props, { root, emit }) => {
    const { addNotification } = useNotify()

    const isEditMode = computed(() => Boolean(props.workPlaceToEdit))

    const FORM_FIELDS: FormField[] = [
      {
        value: 'objectName',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: true,
        rules: [(value: string) => isRequired(value, root.$t('baseData.workPlaces.form.objectName') as string)],
      },
      {
        value: 'level',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [],
      },
      {
        value: 'department',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [],
      },
      {
        value: 'address1',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [],
      },
      {
        value: 'address2',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [],
      },
      {
        value: 'addressZipCode',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [(value: string) => isZipCode(value)],
      },
      {
        value: 'addressCity',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [],
      },
      {
        value: 'addressCountry',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [],
      },
    ]

    const form = ref<NewWorkPlace | WorkPlace>(
      isEditMode.value ? cloneDeep(props.workPlaceToEdit) : ({} as NewWorkPlace)
    )

    const { createWorkPlace, isLoading: isLoadingCreateWorkPlace } = useCreateWorkPlace()

    async function onAdd(): Promise<void> {
      if (!isOfType<WorkPlace>(form.value, 'id')) {
        try {
          await createWorkPlace(form.value)

          close()

          addNotification({
            text: root.$t('baseData.workPlaces.create.message') as string,
            type: 'success',
          })
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const { updateWorkPlace, isLoading: isLoadingUpdateWorkPlace } = useUpdateWorkPlace()

    async function onEdit(): Promise<void> {
      if (isOfType<WorkPlace>(form.value, 'id')) {
        const updatedForm = mapBasicEntityToIdWithForm<WorkPlace>(form.value)

        try {
          await updateWorkPlace(updatedForm.id, updatedForm)

          close()

          addNotification({
            text: root.$t('baseData.workPlaces.edit.message') as string,
            type: 'success',
          })
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const isLoadingAddEditWorkPlace = computed(() => isLoadingCreateWorkPlace.value || isLoadingUpdateWorkPlace.value)

    watch(
      () => props.workPlaceToEdit,
      () => {
        if (props.workPlaceToEdit) {
          form.value = cloneDeep(props.workPlaceToEdit)
        }
      },
      {
        immediate: true,
      }
    )

    function close(): void {
      emit('added-edited')
      emit('close')
    }

    return reactive({
      constants: {
        FORM_FIELDS_ENUM,
        DATA_TYPE,

        FORM_FIELDS,
      },
      state: {
        isEditMode,

        form,

        isLoadingAddEditWorkPlace,
      },
      listeners: {
        onAdd,

        onEdit,
      },
      functions: {
        formRules: {
          isRequired,
        },
      },
    })
  },
})
